import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularmaterialModule } from './modules/angularmaterial/angularmaterial.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './modules/shared/shared.module';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { httpInterceptorProvider } from './providers/httpInterceptor';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import {MatChipsModule} from '@angular/material/chips';
import { hashLocation } from './providers/hashLocation';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    ChangePasswordComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    AngularmaterialModule,
    BrowserAnimationsModule,
    MatChipsModule,
    NgHttpLoaderModule.forRoot(),
    NgbModule
  ],
  exports:[
    SharedModule
  ],
  providers: [hashLocation,httpInterceptorProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { CandidateService } from 'src/app/modules/candidates/services/candidate.service';
import { JobService } from 'src/app/modules/jobs/services/job.service';
import { RecruiterService } from 'src/app/modules/manage-recruiter/services/recruiter.service';
import { SearchService } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  searchValue: any;

  currentRouteName: string = '';
  constructor(
    private http: HttpClient,
    private recruiterService: RecruiterService,
    private jobService: JobService,
    private candiadteService: CandidateService,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.getComponentWithRoute();
  }

  /** export to excel */

  private getComponentWithRoute(): void {
    this.activatedRoute.url.subscribe((route: any) => {
      this.currentRouteName = route[0].path;
    });
  }

  onSearch(event: any) {
    this.searchService.searchData.next({
      currentRouteName: this.currentRouteName,
      searchText: event.target.value
    });
  }

  exportToExcel() {
    if (this.currentRouteName ==='recruiters-listing') {
      this.recruiterService.userDownload().subscribe(
        (res: any) => {
          console.log('category', res);
          var blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          var url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (err: any) => {
          console.log('in err');
          console.log(err);
        }
      );
    }
    if (this.currentRouteName ==='Job-List') {
      this.jobService.jobsDownload().subscribe(
        (res: any) => {
          console.log('job', res);
          var blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          var url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (err: any) => {
          console.log('in err');
          console.log(err);
        }
      );
    }
    if (this.currentRouteName ==='candidates-List') {
      this.candiadteService.candidatesDownload().subscribe(
        (res: any) => {
          console.log('job', res);
          var blob = new Blob([res], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          var url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (err: any) => {
          console.log('in err');
          console.log(err);
        }
      );
    }
    
  }
}

<div class="login_container">
  <div><img src="../../assets/images/kudoslogo.png" /></div>
  <div class="login">
    <h3>Log In</h3>

    <form class="form" [formGroup]="loginForm">
      <div class="form-group">
        <div class="form-group-wrap">
          <label for="Email">Email</label>
          <div class="formbox">
            <small><img src="../../assets/images/email.png" /></small>
            <input
              type="email"
              id="email"
              name="email"
              class="form-control"
              formControlName="email"
              placeholder="  Enter your email address"
            />
            <div *ngIf="submitted && f.email.errors && !(f.password.errors) " class=" text-left">
              <div *ngIf="f.email.errors.required" class="text-danger text-center email-required">
                  Email is required !
              </div>
          </div>
          </div>
          
        </div>
        
      </div>

      <div class="form-group">
        <label for="pwd">Password:</label>
        <div class="formbox">
          <small><img src="../../assets/images/lock.png" /></small>
          <input
            [type]="showPassword?'text':'password'"
            class="form-control"
            id="pwd" 
            name="password"
            minlength="8"
            formControlName="password"
            placeholder="Enter your password"
          />
          <!-- <span class="open-eye"><i class="fas "></i></span> -->
          <span class="close-eye"><i class="fas" [ngClass]="showPassword?'fa-eye-slash':'fa-eye'" (click)="toggleShow()"></i></span>
          <div *ngIf="submitted && f.password.errors && !( f.email.errors)" class=" text-left">
            <div *ngIf="f.password.errors.required" class="text-danger text-center password-required">
                Password is required !
            </div>
        </div>
        </div>
        
      </div>

      <!-- <div class="form-group">
        <div class="flex_forgot">
          <div class="remeber">
            <input type="checkbox" id="bylogin" formControlName="checked" name="checked" value="Login" />
            <label for="signingup">Remember me </label>
          </div>
          <span id="forget"><a [routerLink]="['/forgot-password']">Forgot Password ?</a></span>
        </div>
      </div> -->

      <div class="btn">
        <button class="btn-login" type="submit" (click)="login()">
          Log In
        </button>
        <!-- <button class="btn-login" type="submit" (click)="googlelogin()">Google</button> -->
      </div>
      <div *ngIf="submitted && f.email.errors &&f.password.errors" class=" text-left">
        <div *ngIf="f.email.errors.required" class="text-danger text-center">
            Please enter email and password
        </div>
    </div>
     <p class="text-danger text-center" [hidden]="invalid">Invalid Email and password.</p>
     <p class="text-danger text-center">{{errorMessage}}</p>

    </form>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpcommanService } from 'src/app/services/httpshared.service';
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  static candidateId:any;
  constructor(private _http:HttpcommanService,private http: HttpClient) { }
  url: string = "admin/candidate";
  rurl: string = 'admin/manage-recruiter';

  candidateList(
    page: any,
    search_text: any,
    experience: any,
    department: any,
    recruiter: any
  ) {
    if (experience && department && recruiter) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&experience=${experience}&department=${department}&recruiter=${recruiter}`
      );
    }
    if (experience && department) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&experience=${experience}&department=${department}`
      );
    } else if (experience && recruiter) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&experience=${experience}&recruiter=${recruiter}`
      );
    } else if (department && recruiter) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&department=${department}&recruiter=${recruiter}`
      );
    }else if (experience) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&experience=${experience}`
      );
    } else if (department) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&department=${department}`
      );
    } else if (recruiter) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?page=${page}&recruiter=${recruiter}`
      );
    } else if (search_text) {
      return this._http.getCall(
        `${this.url}/get-candidate-list?search_text=${search_text}&page=${page}`
      );
    } else {
      return this._http.getCall(`${this.url}/get-candidate-list?page=${page}`);
    }
  }

  getCityStateData(countryId: string, stateId?: string) {
    if (countryId && stateId) {
      return this._http.getCall(
        `/web/auth/country-state-city?countryId=${countryId}&stateId=${stateId}`
      );
    } else {
      return this._http.getCall(
        `/web/auth/country-state-city?countryId=${countryId}`
      );
    }
  }

  getCandidateDetail(_id:string){
    return this._http.getCall(`${this.url}/get-candidate-Detail?candidateId=${_id}`)
  }

  candidatesDownload() {
    return this.http.get(
      `${environment.apiUrl}${this.url}/get-all-candidate-to-excel`,
      { responseType: 'blob' }
    );
  }

  editCandidate(data:any){
    return this._http.putCall(`${this.url}/edit-candidate-profile`,data)
      }

      recruitersNames(){
        return this._http.getCall(`${this.rurl}/recruiter-name-listing`);
      }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup = this.formBuilder.group({
    confirmPassword: ['', [Validators.required,Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{10,30}')]],
    newPassword: ['', [Validators.required,Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{10,30}')]],
    oldPassword: ['', [Validators.required,Validators.minLength(6)]],
  });;
  submitted = false;
  constructor(private formBuilder: FormBuilder,private _router: Router,private _matSnackBar: MatSnackBar,private _login:LoginService) { }

  ngOnInit(): void {
  }

  get f():any {
    return this.changePasswordForm.controls;
  }
  saveDetails() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (this.changePasswordForm.value.newPassword != this.changePasswordForm.value.confirmPassword) {
      this._matSnackBar.open('New Password and Confirm Password mismatched.', 'Close', {
        duration: 5000,
      });
      return;
    }
    this.changePasswordForm.value.email="admin@mykudos.app";
    let params={
      old_password:this.changePasswordForm.value.oldPassword,
      new_password:this.changePasswordForm.value.newPassword
    }
    
    this._login.changePassword(params).subscribe(
      (result:any) => {
        this._matSnackBar.open(result.message, 'Close', {
          duration: 3000,
        });
        this._router.navigate(['/login']);
      },
      (err:any) => {
        //console.log(err);
        this._matSnackBar.open(err.error.message, 'Close', {
          duration: 3000,
        });
      }
    );
  }

}

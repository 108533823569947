import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpcommanService } from 'src/app/services/httpshared.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class RecruiterService {
  static recruiterId: any;
  constructor(private _http: HttpcommanService, private http: HttpClient) {}
  url: string = 'admin/manage-recruiter';

  recruiterList(
    page: any,
    search_text: any,
    experience: any,
    admin_approvel: any
  ) {
    if (experience && admin_approvel) {
      return this._http.getCall(
        `${this.url}/recruiter-listing?page=${page}&experience=${experience}&admin_approvel=${admin_approvel}`
      );
    } else if (experience) {
      return this._http.getCall(
        `${this.url}/recruiter-listing?page=${page}&experience=${experience}`
      );
    } else if (admin_approvel) {
      return this._http.getCall(
        `${this.url}/recruiter-listing?page=${page}&admin_approvel=${admin_approvel}`
      );
    } else if (search_text) {
      return this._http.getCall(
        `${this.url}/recruiter-listing?search_text=${search_text}&page=${page}`
      );
    } else {
      return this._http.getCall(`${this.url}/recruiter-listing?page=${page}`);
    }
  }

  getRecruiterByRecruiterId(id: any) {
    return this._http.getCall(`${this.url}/get-recruiter-by-id/${id}`);
  }

  changeStatus(userId: any) {
    return this._http.patchCall(`${this.url}/change-status/${userId}`, {});
  }

  changeApprovelStatus(userId: any, status: any, message?: any) {
    return this._http.patchCall(`${this.url}/admin-approvel/${userId}`, {
      status,
      message,
    });
  }

  userDownload() {
    return this.http.get(
      `${environment.apiUrl}/${this.url}/get-all-recruiter-to-excel`,
      { responseType: 'blob' }
    );
  }
  getCityStateData(countryId: string, stateId?: string) {
    if (countryId && stateId) {
      return this._http.getCall(
        `/web/auth/country-state-city?countryId=${countryId}&stateId=${stateId}`
      );
    } else {
      return this._http.getCall(
        `/web/auth/country-state-city?countryId=${countryId}`
      );
    }
  }

  editRecruiter(data:any){
return this._http.putCall(`${this.url}/edit-recruiter-profile`,data)
  }
}

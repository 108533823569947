<div class="sidebarcontainer">
  <div class="row">
    <div class="col">
      <div class="logo">
        <img src="assets/images/kudoslogo.png" />
      </div>
    </div>
  </div>
  <ul class="sidebarlistwraper">

    <li class="sidebarlist"routerLinkActive="active-menu">
      <img src="assets/images/kudos-icon/dashboard.png" class="icon-wrap" />
      <img src="assets/images/kudos-icon/dashboard_fill.png" class="active-icon" />
      <a [routerLink]="['/dashboard']">Dashboard</a>
    </li>

    <li class="sidebarlist" routerLinkActive="active-menu">
      <img src="assets/images/kudos-icon/recruiter.png" class="icon-wrap" />
      <img src="assets/images/kudos-icon/recruiter_fill.png" class="active-icon" />
      <a [routerLink]="['/recruiters']">Recruiter</a>
    </li>

    <li class="sidebarlist" routerLinkActive="active-menu">
      <img src="assets/images/kudos-icon/candidate.png " class="icon-wrap" />
      <img src="assets/images/kudos-icon/candidate_fill.png " class="active-icon" />
      <a [routerLink]="['/candidates']">Candidates</a>
    </li>

    <li class="sidebarlist" routerLinkActive="active-menu">
      <img src="assets/images/kudos-icon/job.png"  class="icon-wrap"/>
      <img src="assets/images/kudos-icon/job_fill.png " class="active-icon" />
      <a [routerLink]="['/jobs']">Jobs</a>
    </li>
   
    <li class="sidebarlist" routerLinkActive="active-menu">
      <img src="assets/images/kudos-icon/question.png" class="icon-wrap" />
      <img src="assets/images/kudos-icon/question_fill.png" class="active-icon" />

      <a  [routerLink]="['/question-bank']" >Question Bank</a>
    </li>

    <li class="sidebarlist" routerLinkActive="active-menu">
      <img src="../../../../../assets/images/kudos-icon/interview.png" class="icon-wrap" />
      <img src="../../../../../assets/images/kudos-icon/interview_fill.png" class="active-icon" />
      <a [routerLink]="['/interview']">Interviews</a>
    </li>



    <li class="sidebarlist" routerLinkActive="active-menu">
      <img src="assets/images/reviewandrat.png"  class="icon-wrap" />
      <img src="assets/images/kudos-icon/job_fill.png " class="active-icon" />
      <a [routerLink]="['/review-and-rating/Review-Rating']">Review and Rating</a>
    </li>




    <li class="sidebarlist changepassword" routerLinkActive="active-menu">
      <i class="fas fa-fingerprint"></i>
      <a [routerLink]="['/change-password']">Change Password</a>
    </li>
    
    <li class="sidebarlist logout">
     <img src="assets/images/logout.png">
      <a data-bs-toggle="modal"
      data-bs-target="#exampleModal">Logout</a>
    </li>


  </ul>
</div>

<!-- reject modal -->
<!-- Button trigger modal -->

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
     
      
        <div class=" modals-wrapper">
          <img src="../../../../../assets/images/circularalert.png" />
          <h3>Are you sure want to logout?</h3>

          <div id="modal-footer-but">
            <button
              type="button"
              class="btn btn-danger btn-lg large-button"
              data-bs-dismiss="modal"
            >
              No
            </button>
            <button type="button" class="btn btn-primary btn-lg large-button"    data-bs-dismiss="modal" (click)="logout()">Yes</button>
          </div>
        </div>
     
    </div>
  </div>
</div>



<div class="hamberger-sidbar">
<a class="hemburger" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
  <em></em>
</a>


<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div class="sidebar_container">
      <div class="row">
        <div class="col">
          <div class="logo">
            <img src="assets/images/kudoslogo.png" />
          </div>
        </div>
      </div>
      <ul class="sidebarlistwraper">
        <li class="sidebarlist"routerLinkActive="active-menu">
          <img src="assets/images/catogery.png" class="icon-wrap" />
          <img src="assets/images/calender.png" class="active-icon" />
          <a [routerLink]="['/dashboard']">Dashboard</a>
        </li>
    
        <li class="sidebarlist" routerLinkActive="active-menu">
          <img src="assets/images/calender.png" />
          <a [routerLink]="['/recruiters']">Recruiter</a>
        </li>
    
        <li class="sidebarlist" routerLinkActive="active-menu">
          <img src="assets/images/cameraanswer.png" />
          <a [routerLink]="['/candidates/candidates-List']">Candidates</a>
        </li>
    
        <li class="sidebarlist" routerLinkActive="active-menu">
          <img src="assets/images/cameraanswer.png" />
          <a [routerLink]="['/jobs/Job-List']">Jobs</a>
        </li>
       
        <li class="sidebarlist" routerLinkActive="active-menu">
          <img src="assets/images/reviewandrat.png" />
          <a  [routerLink]="['/question-bank/Question-Bank']" > Question Bank</a>
        </li>
    
        <!-- <li class="sidebarlist">
          <img src="../../../../../assets/images/reviewandrat.png" />
          <a href="#"> Interviews</a>
        </li> -->
    
        <li class="sidebarlist" routerLinkActive="active-menu">
          <img src="assets/images/reviewandrat.png" />
          <a [routerLink]="['/review-and-rating/Review-Rating']"> Review and Rating</a>
        </li>
    
        <li class="sidebarlist changepassword" routerLinkActive="active-menu">
          <i class="fas fa-fingerprint"></i>
          <a [routerLink]="['/change-password']">Change Password</a>
        </li>
        
        <li class="sidebarlist logout">
         <img src="assets/images/logout.png">
          <a   data-bs-toggle="modal"
          data-bs-target="#exampleModal">Logout</a>
        </li>
    
    
      </ul>
    </div>
    
    
  </div>
</div>

</div>
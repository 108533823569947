<div class="login_container">
  <div><img src="../../assets/images/kudoslogo.png" /></div>
  <div class="login">
    <h3>Forget Password</h3>
    <p>
      Enter the email address associalted with your account and we’ll send you a
      link to reset your password.
    </p>

    <form>
      <div class="form-group-wrap">
        <div class="form-group">
          <label for="Email">Email</label>
          <div class="formbox">
            <span><img src="../../assets/images/email.png" /></span>
            <input
              type="email"
              id="email"
              class="form-control"
              name="email"
              placeholder="  Enter your email address"
            />
          </div>
        </div>
      </div>

      <div class="btn">
        <button class="btn-login" type="submit">Reset Password</button>
      </div>
    </form>
  </div>
  
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpcommanService } from 'src/app/services/httpshared.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  static jobId: any;
  constructor(private _http: HttpcommanService, private http: HttpClient) {}
  url: string = 'admin/job';
  rurl: string = 'admin/manage-recruiter';

  jobList(
    page: any,
    search_text: any,
    experience: any,
    department: any,
    job_type: any,
    recruiter: any
  ) {
    if (experience && department && job_type && recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&department=${department}&job_type=${job_type}&recruiter=${recruiter}`
      );
    }
    if (experience && department && job_type) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&department=${department}&job_type=${job_type}`
      );
    }
    if (experience && department && recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&department=${department}&recruiter=${recruiter}`
      );
    }
    if (experience && department && job_type && recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&department=${department}&job_type=${job_type}&recruiter=${recruiter}`
      );
    }
    if (experience && job_type) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&job_type=${job_type}`
      );
    } else if (experience && department) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&department=${department}`
      );
    } else if (experience && recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}&recruiter=${recruiter}`
      );
    } else if (department && job_type) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&department=${department}&job_type=${job_type}`
      );
    } else if (department && recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&department=${department}&recruiter=${recruiter}`
      );
    } else if (job_type && recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&recruiter=${recruiter}&job_type=${job_type}`
      );
    } else if (experience) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&experience=${experience}`
      );
    } else if (department) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&department=${department}`
      );
    } else if (job_type) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&job_type=${job_type}`
      );
    } else if (recruiter) {
      return this._http.getCall(
        `${this.url}/get-job-list?page=${page}&recruiter=${recruiter}`
      );
    } else if (search_text) {
      return this._http.getCall(
        `${this.url}/get-job-list?search_text=${search_text}&page=${page}`
      );
    } else {
      return this._http.getCall(`${this.url}/get-job-list?page=${page}`);
    }
  }

  getJobDetail(_id: string) {
    return this._http.getCall(`${this.url}/get-job-Detail?jobId=${_id}`);
  }
  deleteJob(_id: string) {
    return this._http.deleteCall(`${this.url}/delete-job`, _id);
  }

  getCityStateData(countryId: string, stateId?: string) {
    if (countryId && stateId) {
      return this._http.getCall(
        `/web/auth/country-state-city?countryId=${countryId}&stateId=${stateId}`
      );
    } else {
      return this._http.getCall(
        `/web/auth/country-state-city?countryId=${countryId}`
      );
    }
  }
  editJob(data: any) {
    console.log('data',data);
    
    return this._http.putCall(`${this.url}/edit-job-detail`, data);
  }

  jobsDownload() {
    return this.http.get(
      `${environment.apiUrl}${this.url}/get-all-job-to-excel`,
      { responseType: 'blob' }
    );
  }

  recruitersNames(){
    return this._http.getCall(`${this.rurl}/recruiter-name-listing`);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ProtectGuard } from './guards/protect.guard';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { AllInterviewComponent } from './modules/interview/components/all-interview/all-interview.component';

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "*", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    canActivate: [ProtectGuard],
    component: LoginComponent
  },
  {
    path:'dashboard',
    canActivate: [AuthGuard],
    loadChildren: ()=>import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    component:ChangePasswordComponent
  },
  {
    path: 'forgot-password',
    canActivate: [ProtectGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'new-password',
    canActivate: [ProtectGuard],
    component:NewPasswordComponent
  },
  {
    path:'recruiters',
    canActivate: [AuthGuard],
    loadChildren: ()=>import('./modules/manage-recruiter/manage-recruiter.module').then(m => m.ManageRecruiterModule)
  },

  {
    path:'candidates',
    canActivate: [AuthGuard],
    loadChildren: ()=>import('./modules/candidates/candidates.module').then(m => m.CandidatesModule)
  },

  {
    path:'jobs',
    canActivate: [AuthGuard],
    loadChildren: ()=>import('./modules/jobs/jobs.module').then(m => m.JobsModule)
  },

  {  
  path:'review-and-rating',
  canActivate: [AuthGuard],
  loadChildren:()=>import('./modules/review-and-rating/review-and-rating.module').then(m => m.ReviewAndRatingModule) 
  },


  {  
    path:'question-bank',
    canActivate: [AuthGuard],
    loadChildren:()=>import('./modules/question-bank/question-bank.module').then(m => m.QuestionBankModule) 
   },

   {

    path:'answers',
    canActivate:[AuthGuard],
    loadChildren:()=>import('./modules/answers/answers.module').then(m => m.AnswersModule)
   },
   {
    path:'interview',
    canActivate:[AuthGuard],
    loadChildren:()=>import('./modules/interview/interview.module').then(m => m.InterviewModule)

   },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private _route:Router) { }

  ngOnInit(): void {
  }
  logout() {
   
        sessionStorage.clear();    
        localStorage.setItem('token','');  
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.clear();
        this._route.navigate(['/login']);   

  
  }

}




<div class="header">
    <div class="serachbarwrapper">

    <form>
    <div class="form-group">
        <div class="searchbar">
        <button class="btns" type="submit"><img src="../../../../../assets/images/search.png"></button>
        <input class="form-control me-2 form-control" type="search" placeholder="Search Recruiter" aria-label="Search" (input)="onSearch($event)">
        </div>
    </div>

    </form>

    <div class="profile">
        <button type="button" class="btn btn-success" (click)="exportToExcel()"> Export To Excel</button>
    </div>
    </div>
   
</div>

    
    



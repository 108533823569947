import { Injectable } from '@angular/core';
import { HttpcommanService } from './httpshared.service';
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private httpService: HttpcommanService,
    private _http: HttpClient
  ) { }

  login(data:any) {
    console.log('data',data);
    return this.httpService.postCall("admin/auth/login", data);
  }
  googlelogin(data:any) {
    console.log('data',data);
    let socaildata =this._http.post<any>(`https://api.mykudos.app/api/v1/web/auth/social-login`, data);
    console.log('socaildata',socaildata);
    return socaildata;
  }
  changePassword(params: any) {
    return this.httpService.patchCall('admin/auth/change-password', params);
   
  }
}

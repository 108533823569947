import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import {MatSnackBar} from '@angular/material/snack-bar'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  invalid:any=true;
  submitted:any=false;
  email:any;
  password:any;
  required:any =true;
  showPassword=false;
  inputType:any;
  checked:any=false;
  errorMessage:any;

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  loginForm:FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email,Validators.pattern(this.emailregex)]],
    password: ['', [Validators.required]],
    checked:['']
  });
  constructor(private _login:LoginService,private _route:Router,private formBuilder: FormBuilder, private snack: MatSnackBar) {}

  ngOnInit(): void {
  }
  get f():any {
    return this.loginForm.controls;
  }
  login() {
    console.log('clicked', this.loginForm.value, this.loginForm.invalid);
    
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.errorMessage = 'Invalid Email and password.';
    }
    this.checked = this.loginForm.value.checked;
    console.log('this.checked',this.loginForm.value.password);
    
    if (this.checked) {
      localStorage.setItem('email',this.loginForm.value.email)
      localStorage.setItem('password',this.loginForm.value.password)
      let login = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }
      this._login.login(login).subscribe(
        result => {
          console.log(result);
  
          if (result.status == 200) {
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('token', result.data.user.token);
              this._route.navigate(['/dashboard']);
          } else this.invalid = false;
        },
        err => {
          
          this.invalid = false;
        }
      );
    } else {
      let login = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }
      console.log('login',login);
      
      this._login.login(login).subscribe(
        result => {
          console.log(result);
  
          if (result.status == 200) {
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('token', result.data.user.token);
              this._route.navigate(['/dashboard']);
          } else this.invalid = false;
        },
        err => {
          console.log(err);
          if (err.status == 401) {
            this.errorMessage = 'Invalid Email and password.'
          }
        }
      );
    }
    


  }
  
  toggleShow() {
    this.showPassword = !this.showPassword;
  }

  googlelogin(){
    let data ={
      email:'vinay.sunny1994@gmail.com',
      account_type:'GMAIL',
      social_id:'hcbhjhjcbhjcbhjcsccabhjcbhj',
      social_token: 'cbhjcbhjcbhjbchjchjchjchjvchjvcavacvcavcagvgacsvgcasvgjcsvhjcasvhjcasvhasvh',
      is_profile_completed:false,
    }

    this._login.googlelogin(data).subscribe(
      result => {
        console.log('social',result);

        if (result.status == 200 || result.status == 201) {
            this._route.navigate(['/dashboard']);
        } else this.invalid = false;
      },
      err => {
        console.log(err);
        if (err.status == 401) {
          this.errorMessage = 'Invalid Email and password.'
        }
      }
    );
  }

}

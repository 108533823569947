<div class="main-wrapper">
  <app-sidebar></app-sidebar>

  <div class="change-password">
    <div class="change-password-container">
      <h4>Change Password</h4>

      <div class="form-container">
        <form [formGroup]="changePasswordForm">
          <div class="form-group col-md-12">
            <label for="exampleInputPassword1">Current Password</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              placeholder=" Enter Current Password"
              (keydown.space)="$event.preventDefault();" formControlName="oldPassword" name="oldPassword"
            />
            <div *ngIf="submitted && f.oldPassword.errors" class=" text-left">
              <div *ngIf="f.oldPassword.errors.required" class="validation_color text-danger">
                Old Password is required !
              </div>
              <div *ngIf="f.oldPassword.errors.minlength" class="validation_color text-danger">
                 Password must be at least 6 characters.
              </div>
            </div>
          </div>

          <div class="form-group col-md-12">
            <label for="exampleInputPassword1">New Password</label>
            <input
            placeholder=" Enter New Password"
            (keydown.space)="$event.preventDefault();" type="password" class="form-control" formControlName="newPassword" name="newPassword"
            />
            <div *ngIf="submitted && f.newPassword.errors" class=" text-left">                      
              <div *ngIf="f.newPassword.errors.required" class="validation_color text-danger">
                New Password is required !                          
              </div>
              <div *ngIf="f.newPassword.errors.minlength" class="validation_color text-danger">
                 Password must be at least 6 characters.
              </div>
            </div>
          </div>

          <div class="form-group col-md-12">
            <label for="exampleInputPassword1">Confirm Password</label>
            <input
            placeholder=" Enter Confirm Password"
              type="password"
              class="form-control"
              formControlName="confirmPassword"
              name="confirmPassword"
              (keydown.space)="$event.preventDefault();"
            />
            <div *ngIf="submitted && f.confirmPassword.errors" class=" text-left">
              <div *ngIf="f.confirmPassword.errors.required" class="validation_color text-danger">
                Confirm Password is required !
              </div>
              <div *ngIf="f.confirmPassword.errors.minlength" class="validation_color text-danger">
                 Password must be at least 6 characters.
              </div>
            </div>
          </div>
          <div class="btn-wrapper">

          <div class="row">
            <div class="col-md-6">
              <button type="button" class="btn btn-secondary btn-lg">
                Cancel
              </button>
            </div>
            <div class="col-md-6">
              <button type="button" class="btn btn-primary btn-lg">
                Submit
              </button>
            </div>
          </div>

        </div>
        </form>
      </div>
    </div>
  </div>
</div>
